var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.apiCalls)?_c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.apiCalls.list.title[_vm.languageSelected]}},[_c('AddIntegration',{ref:"addIntegration",attrs:{"integration-type":_vm.integrationType,"base-url":_vm.cyberbankUrl}}),_vm._v(" "),_c('EditIntegration',{ref:"editIntegration",attrs:{"integration":_vm.selected}}),_vm._v(" "),_c('KonaTabs',[(
        _vm.useCyberbank && _vm.cyberbankList && Object.keys(_vm.cyberbankList).length > 0
      )?_c('KonaTab',{attrs:{"name":_vm.lang.botMaker.integrations.tabs.cyberbank[_vm.languageSelected],"selected":_vm.useCyberbank && _vm.cyberbankList && Object.keys(_vm.cyberbankList).length > 0}},[(_vm.baseUrl)?_c('KonaAlert',{attrs:{"icon":"LinkIcon","bg-color":"rgba(var(--vs-success), 0.15)","color":"rgba(var(--vs-success), 1)","size":"small"}},[_vm._v("\n        "+_vm._s(_vm.lang.apiCalls.baseUrl[_vm.languageSelected])+": \n        "),_c('strong',[_vm._v(_vm._s(_vm.baseUrl)+"/")])]):_vm._e(),_vm._v(" "),_c('vs-collapse',{attrs:{"accordion":""}},_vm._l((Object.keys(_vm.cyberbankList)),function(groupName,index){return _c('vs-collapse-item',{key:index},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("\n            "+_vm._s(_vm.cyberbankNames[groupName])+"\n          ")]),_vm._v(" "),(_vm.cyberbankList[groupName])?_c('vs-table',{attrs:{"data":_vm.cyberbankList[groupName]},on:{"selected":_vm.handleSelectedCyberbank},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].name}},[_vm._v("\n                  "+_vm._s(data[indextr].name)+"\n                ")]),_vm._v(" "),_c('vs-td',{staticClass:"float-right m-2"},[_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"icon-plus","icon-pack":"feather","size":"large"},on:{"click":function($event){_vm.addIntegration('cyberbank')}}})],1)],1)})}}]),model:{value:(_vm.selectedCyberbank),callback:function ($$v) {_vm.selectedCyberbank=$$v},expression:"selectedCyberbank"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"url"}},[_vm._v("\n                "+_vm._s(_vm.lang.apiCalls.list.url[_vm.languageSelected])+"\n              ")]),_vm._v(" "),_c('vs-th')],1)],2):_vm._e()],1)}),1)],1):_vm._e(),_vm._v(" "),_c('KonaTab',{attrs:{"name":_vm.lang.botMaker.integrations.tabs.integrations[_vm.languageSelected],"selected":!_vm.useCyberbank ||
          !_vm.cyberbankList ||
          (_vm.cyberbankList && Object.keys(_vm.cyberbankList).length === 0)}},[_c('div',{staticClass:"flex justify-end"},[_c('vs-button',{attrs:{"color":"primary","type":"filled"},on:{"click":function($event){_vm.addIntegration('')}}},[_vm._v("\n          "+_vm._s(_vm.lang.apiCalls.add.button[_vm.languageSelected])+"\n        ")])],1),_vm._v(" "),_c('vs-table',{staticClass:"mt-4",attrs:{"data":_vm.apiCalls,"max-items":"5","pagination":"","sorted":"","no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},on:{"selected":_vm.handleSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,staticClass:"m-0 p-0",attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].url}},[_vm._v(_vm._s(data[indextr].url))]),_vm._v(" "),_c('vs-td',{attrs:{"data":data[indextr].headers}},[_c('vs-list',_vm._l((Object.keys(
                    data[indextr].headers
                  )),function(header,indexList){return _c('vs-list-item',{key:indexList,attrs:{"title":_vm.lang.apiCalls.list.headers.header[_vm.languageSelected],"subtitle":data[indextr].headers[header]}})}),1)],1),_vm._v(" "),_c('vs-td',{staticClass:"float-right m-2"},[_c('vs-col',{staticClass:"flex justify-center"},[_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"edit","size":"large"},on:{"click":function($event){_vm.edit(indextr)}}}),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","type":"flat","icon-pack":"feather","icon":"icon-trash","size":"large"},on:{"click":function($event){_vm.remove(data[indextr]._id)}}})],1)],1)],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"url"}},[_vm._v("\n            "+_vm._s(_vm.lang.apiCalls.list.url[_vm.languageSelected])+"\n          ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n            "+_vm._s(_vm.lang.apiCalls.list.headers.title[_vm.languageSelected])+"\n          ")]),_vm._v(" "),_c('vs-th')],1)],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }