<template>
  <vx-card
    :title="lang.apiCalls.list.title[languageSelected]"
    class="overflow-hidden mb-2"
    v-if="apiCalls"
  >
    <AddIntegration
      ref="addIntegration"
      :integration-type="integrationType"
      :base-url="cyberbankUrl"
    />
    <EditIntegration ref="editIntegration" :integration="selected" />

    <KonaTabs>
      <!-- cyberbank apis -->
      <KonaTab
        :name="lang.botMaker.integrations.tabs.cyberbank[languageSelected]"
        :selected="
          useCyberbank && cyberbankList && Object.keys(cyberbankList).length > 0
        "
        v-if="
          useCyberbank && cyberbankList && Object.keys(cyberbankList).length > 0
        "
      >
        <KonaAlert
          icon="LinkIcon"
          bg-color="rgba(var(--vs-success), 0.15)"
          color="rgba(var(--vs-success), 1)"
          size="small"
          v-if="baseUrl"
        >
          {{ lang.apiCalls.baseUrl[languageSelected] }}:&nbsp;
          <strong>{{ baseUrl }}/</strong>
        </KonaAlert>

        <vs-collapse accordion>
          <vs-collapse-item
            :key="index"
            v-for="(groupName, index) in Object.keys(cyberbankList)"
          >
            <div slot="header">
              {{ cyberbankNames[groupName] }}
            </div>

            <vs-table
              v-model="selectedCyberbank"
              @selected="handleSelectedCyberbank"
              :data="cyberbankList[groupName]"
              v-if="cyberbankList[groupName]"
            >
              <template slot="thead">
                <vs-th sort-key="url">
                  {{ lang.apiCalls.list.url[languageSelected] }}
                </vs-th>
                <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].name">
                    {{ data[indextr].name }}
                  </vs-td>
                  <vs-td class="float-right m-2">
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="icon-plus"
                      icon-pack="feather"
                      size="large"
                      @click="addIntegration('cyberbank')"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-collapse-item>
        </vs-collapse>
      </KonaTab>

      <!-- integrations -->
      <KonaTab
        :name="lang.botMaker.integrations.tabs.integrations[languageSelected]"
        :selected="
          !useCyberbank ||
            !cyberbankList ||
            (cyberbankList && Object.keys(cyberbankList).length === 0)
        "
      >
        <div class="flex justify-end">
          <vs-button color="primary" type="filled" @click="addIntegration('')">
            {{ lang.apiCalls.add.button[languageSelected] }}
          </vs-button>
        </div>
        <vs-table
          :data="apiCalls"
          v-model="selected"
          @selected="handleSelected"
          max-items="5"
          pagination
          sorted
          class="mt-4"
          :no-data-text="lang.general.noDataText[languageSelected]"
        >
          <template slot="thead">
            <vs-th sort-key="url">
              {{ lang.apiCalls.list.url[languageSelected] }}
            </vs-th>
            <vs-th>
              {{ lang.apiCalls.list.headers.title[languageSelected] }}
            </vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              class="m-0 p-0"
            >
              <vs-td :data="data[indextr].url">{{ data[indextr].url }}</vs-td>
              <vs-td :data="data[indextr].headers">
                <vs-list>
                  <vs-list-item
                    :key="indexList"
                    v-for="(header, indexList) in Object.keys(
                      data[indextr].headers
                    )"
                    :title="lang.apiCalls.list.headers.header[languageSelected]"
                    :subtitle="data[indextr].headers[header]"
                  ></vs-list-item>
                </vs-list>
              </vs-td>
              <vs-td class="float-right m-2">
                <vs-col class="flex justify-center">
                  <vs-button
                    color="primary"
                    type="flat"
                    icon="edit"
                    size="large"
                    @click="edit(indextr)"
                  ></vs-button>
                  <vs-button
                    color="primary"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-trash"
                    size="large"
                    @click="remove(data[indextr]._id)"
                  ></vs-button>
                </vs-col>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </KonaTab>
    </KonaTabs>
  </vx-card>
</template>

<script>
import AddIntegration from './AddIntegration.vue'
import EditIntegration from './EditIntegration.vue'

import BotMakerService from '../../../services/botMaker.service'
import CyberbankService from '@/services/cyberbank.service'

import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'IntegrationsList',
  components: {
    AddIntegration,
    EditIntegration,
    KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd'),
    KonaAlert: () => import('@/components/KonaAlert.vue')
  },
  data() {
    return {
      removing: false,
      selected: null,
      integrationType: '',
      cyberbankIntegrations: [],
      cyberbankList: [],
      cyberbankCurrentVersion: 'v1',
      selectedCyberbank: null,
      baseUrl: '',
      cyberbankUrl: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'useCyberbank']),
    ...mapGetters('botMaker', ['bot', 'apiCalls']),
    cyberbankNames() {
      const acc = {}
      this.cyberbankIntegrations.forEach(ci => {
        acc[ci.groupId] = ci.groupName
      })
      return acc
    }
  },
  methods: {
    ...mapMutations('botMaker', ['SPLICE_API_CALL']),
    ...mapActions('botMaker', ['GET_API_CALLS', 'GET_BOT']),
    handleSelected(selected) {
      this.$refs.editIntegration.updateFields(selected)
    },
    addIntegration(type = '') {
      this.integrationType = type
      this.$refs.addIntegration.openPopup()
    },
    edit() {
      this.$refs.editIntegration.openPopup()
    },
    remove(integrationId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.apiCalls.remove.dialog.title[this.languageSelected],
        text: this.lang.apiCalls.remove.dialog.text[this.languageSelected],
        acceptText: this.lang.sidebar.logout.confirm.accept[
          this.languageSelected
        ],
        cancelText: this.lang.sidebar.logout.confirm.cancel[
          this.languageSelected
        ],
        accept: async () => {
          this.removing = true
          try {
            await BotMakerService.removeApiCall(integrationId)
            this.SPLICE_API_CALL(this.apiCalls.findIndex(i => i._id === integrationId));
            this.$vs.notify({
              title: this.lang.apiCalls.remove.success.title[
                this.languageSelected
              ],
              text: this.lang.apiCalls.remove.success.text[
                this.languageSelected
              ],
              color: 'success',
              position: 'top-right'
            })
          } catch (error) {
            this.$vs.notify({
              title: this.lang.apiCalls.remove.error.title[
                this.languageSelected
              ],
              text: this.lang.apiCalls.remove.error.text[this.languageSelected],
              color: 'danger',
              position: 'top-right'
            })
          }
          this.removing = false
        }
      })
    },
    async getCyberbankApis() {
      this.$vs.loading()
      try {
        const response = await CyberbankService.getCyberbankApis()
        this.baseUrl =
          response.data && response.data[0] && response.data[0].baseUrl
        this.cyberbankIntegrations =
          response.data && response.data[0] && response.data[0].integrations

        this.cyberbankCurrentVersion = response.data[0].currentVersion
        this.cyberbankList = this.cyberbankIntegrations.reduce(
          (objectsByKeyValue, obj) => ({
            ...objectsByKeyValue,
            [obj.groupId]: (objectsByKeyValue[obj.group] || []).concat(
              obj.integrations
            )
          }),
          {}
        )

        // this.apis = response.reduce(
        //   (objectsByKeyValue, obj) => ({
        //     ...objectsByKeyValue,
        //     [obj.group]: (objectsByKeyValue[obj.group] || []).concat(obj)
        //   }),
        //   {}
        // )

        this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close()
        console.error(error)
      }
    },
    handleSelectedCyberbank(tr) {
      this.cyberbankUrl =
        this.baseUrl +
        '/' +
        this.cyberbankCurrentVersion +
        '/' +
        tr.group +
        '/' +
        tr.name
    }
  },
  mounted() {
    if (this.useCyberbank) {
      this.getCyberbankApis()
    }
  }
}
</script>
